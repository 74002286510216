<template>
  <app-bar active="/client">
    <div class="wrap">
      <div class="content">
        <el-tabs v-model="activeName">
          <el-tab-pane label="客户信息" name="1">
            <el-form ref="form" label-width="auto" :model="form">
              <el-form-item label="客户LOGO">
                <el-upload
                  class="avatar-uploader"
                  action="//ghf.site.yscase.com/api/v1.index/uploadPic"
                  :headers="token"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>

              <el-row>
                <el-col :span="11">
                  <el-form-item label="客户名称">
                    <el-input
                      v-model="form.name"
                      placeholder="请输入客户名称"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                  <el-form-item label="已有客户关联">
                    <el-input
                      v-model="form.relas"
                      placeholder="可输入名称后下拉选择"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item label="客户地址">
                <el-input
                  v-model="form.address"
                  placeholder="请输入客户详细地址"
                ></el-input>
              </el-form-item>

              <el-row>
                <el-col :span="11">
                  <el-form-item label="联系人">
                    <el-input
                      v-model="form.lxr"
                      placeholder="请输入联系人"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                  <el-form-item label="联系方式">
                    <el-input
                      v-model="form.tel"
                      placeholder="请输入联系人电话号码"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item label="客户备注">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  v-model="form.note"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-form>

            <el-button type="info" round @click="release">发布</el-button>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </app-bar>
</template>

<style lang="less" scoped>
@deep: ~">>>";
.el-tab-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.el-button {
  min-width: 100px;
}
.wrap {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;
}
.content {
  height: 100%;
  margin: 20px;
}
@{deep} {
  #tab-1,
  .el-tabs__active-bar {
    margin-left: 20px;
  }
  .el-tabs__item.is-active {
    color: #f9c26b;
  }
  .el-tabs__active-bar {
    background-color: #f8b849;
  }
  .el-form-item__content {
    line-height: 1;
  }
  .avatar-uploader .el-upload {
    width: 300px;
    height: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: #ebebeb;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    // width: 178px;
    // height: 178px;
    display: block;
  }
}
.el-form {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
}
</style>

<script>
import AppBar from "../../components/AppBar.vue";
export default {
  data() {
    return {
      imageUrl: "",
      activeName: "1",
      form: {
        name: "",
        thumb: "",
        address: "",
        lxr: "",
        tel: "",
        note: "",
        relas: ""
      }
    };
  },
  methods: {
    release() {
      this.$common.postInterface(
        "/api/v1.customer/add",
        {
          name: this.form.name,
          thumb: this.form.thumb ? this.form.thumb : "",
          address: this.form.address,
          lxr: this.form.lxr,
          tel: this.form.tel,
          note: this.form.note,
          relas: ""
        },
        this,
        res => {
          if (res.status === "200") {
         setmas(res.msg,"success",this)

            // this.$message({
            //   message: res.msg,
            //   type: "success"
            // });
            // this.compile = true;
            // this.$emit("getList");
            this.$router.replace({ name: "Client" });
          } else {
         setmas(res.msg,"warning",this)

            // this.$message({
            //   message: res.msg,
            //   type: "warning"
            // });
          }
          // this.userData = res.data;
          // console.log(res);
        }
      );
    },
    handleAvatarSuccess(res, file) {
      // console.log(res);
      this.form.thumb = res.data;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
         setmas("上传头像图片只能是 JPG/PNG 格式!","error",this)

        // this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
         setmas("上传头像图片大小不能超过 5MB!","error",this)

        // this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    }
  },
  computed: {
    token: () => {
      return { token: window.sessionStorage.getItem("token") };
    }
  },
  components: { AppBar }
};
// 提醒弹出框
function setmas(msg,type,vm){
       vm.$message({
                  message:msg,
                  type:type,
                  customClass:"popup"
                  
    });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;

  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>